<template>
  <div class="upload">
    <p class=" ">转账记录</p>
    <div class="image-tan">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage"
        style="visibility: hidden"
        @change="selectFileImage($event)"
      />
      <label for="uploadImage">
        <van-image class="showimage-tan" :src="pic || defaultPic"></van-image>
      </label>
    </div>
    <div v-if="pdf_name" class="pdf-name">{{pdf_name}}</div>
    <div class="upload-tip theme-color">点击图片上传或更新（jpg/jpeg/png/pdf）</div>
    <div class="common-transferacc-tip theme-color">
      <span>提示：</span><br />
      <span>1.必须为香港的银行转账, 转账不少于1万的港币或等值美元，转账时请备注XXX开户（XXX为您的姓名）。</span><br />
      <span>2.点击查看 <span class="link" @click="handleShowAccount">收款账户</span></span><br />
      <span>3.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_3address_01.jpg')">
        转账记录
    </span>。</span><br />
  </div>

  </div>
</template>

<script>
import {
  Uploader, Image, ImagePreview, Dialog,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';
import uploadImage from './uploadImage';
import util from '../../utils/util';

const alertMsg = `
注意：
1.必须用认证身份所绑定的银行卡转账不少于1万的港币或等值美元；
2.若未及时上传汇款凭证，后续请将凭证发送到customer-service@beevestsec.com，以便加快核实。

港币账户（中文）
银行名称： 中国银行（香港）有限公司
银行地址： 香港花园道1号中国银行大厦
SWIFT码： BKCHHKHH
户口名称： 蜂投证券有限公司
账户号码： 012-884-0-010359-5
附注：XXX开户（如：张三开户）

港币账户（英文）
银行名称： Bank of China (Hong Kong) Ltd
银行地址： Bank of China Tower, 1 Garden Road, Hong Kong
SWIFT码： BKCHHKHH
户口名称： BEEVEST SECURITIES LIMITED (CLIENTS' A/C)
账户号码： 012-884-0-010359-5
附注：XXX account opening (e.g. Peter account opening)

美元账户（中文）
银行名称： 中国银行(香港)有限公司
银行地址： 香港花园道1号中国银行大厦
SWIFT码： BKCHHKHH
户口名称： 蜂投证券有限公司
账户号码： 012-884-0-801729-2
附注：XXX开户（如：张三开户）

美元账户（英文）
银行名称： Bank of China (Hong Kong) Ltd
银行地址： Bank of China Tower, 1 Garden Road, Hong Kong
SWIFT码： BKCHHKHH
户口名称： BEEVEST SECURITIES LIMITED (CLIENTS' A/C)
账户号码： 012-884-0-801729-2
附注：XXX account opening (e.g. Peter account opening)
`;
const defaultPdf = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/7_pdf.png';
const defaultImage = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/3_live.png';
export default {
  name: 'Home',
  components: {
    [Uploader.name]: Uploader,
    [Image.name]: Image,
  },
  data() {
    return {
      defaultPic: defaultImage,
    };
  },
  methods: {
    handleShowAccount() {
      Dialog.alert({
        title: '收款账户',
        messageAlign: 'left',
        confirmButtonText: '我知道了',
        message: alertMsg,
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },
    async selectFileImage(event) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf') {
        const form = new FormData();
        form.append('file', file, this.$util.setPdfName(file.name));
        const result = await this.$api.uploadPdf('transfer_pdf', form);
        const { data = {} } = (result || { data: {} }).data;
        if (data.fileName.endsWith('.pdf')) {
          this.pic = defaultPdf;
          this.pdf_name = this.$util.formatPdfName(data.fileName);
        }
      } else {
        uploadImage(file, 'transfer_photo', (photo) => {
          this.pic = photo;
          this.pdf_name = '';
        });
      }
    },
    showPreview(key) {
      ImagePreview([key]);
    },
    next() {
      this.$router.push({ path: '/info2' });
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const fileList = ref([]);
    const pic = ref('');
    const pdf_name = ref('');
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsShow', false);
      const result = await httpApi.getUploadImgesByType({ type: 'transfer_photo' });
      if (!result) return;
      const { value } = result.data;
      if (!value) return;
      if (value.endsWith('.pdf')) {
        pic.value = defaultPdf;
        pdf_name.value = util.formatPdfName(value);
      } else {
        pic.value = value;
      }
    });
    return {
      pic,
      pdf_name,
      fileList,
    };
  },
};
</script>

<style lang="less">
  .image-tan {
    width: 300px;
    height: 200px;
    margin: auto;
    position: relative;
  }
  .showimage-tan {
    position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
  }
  .upload-tip {
    text-align: center !important;
    color: @font-color-2;
    margin-top: @space-2;
  }
  .upload {
    p {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  .common-transferacc-tip {
    margin-top: 10px;
    color: @font-color-1;
  }
  .pdf-name {
    margin: auto;
    text-align: center !important;
    margin: 20px;
  }
</style>
